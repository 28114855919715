import React from 'react'
function Privacy_policy() {
  return (<section id="home" style={{ padding: "0 !important" }}>
    <div style={{ marginTop: "8.7%" }} />
    <main className="content parent-content">
      <main className="content">
        <section className="static-sections static-header container border-bottom text-center section-head">
          <h1>Privacy policy</h1>
        </section>
        <section className="static-sections">
          <div className="mini-container" style={{ textAlign: "justify" }}>
            <h3>Privacy Statement</h3>
            <p className="text-grey">
              The confidentiality of your personal details remains paramount at
              OUR BROKING. We hold a strong commitment to safeguarding your
              privacy and ensuring that your personal data remains protected
              against unsolicited access. Our team is trained meticulously to
              honor and preserve your privacy, adhering to our institutional
              standards, protocols, and procedures.
            </p>
            <p className="text-grey">
              When you opt for a product or service from OUR BROKING, we gather
              and store your personal information for:
            </p>
            <ul>
              <li>
                Effective management and administration of said product or
                service.{" "}
              </li>
              <li>
                Assisting you with the pertinent product or service, which
                includes evaluating your application and confirming your identity.
              </li>
              <li>Fraud prevention related to the product or service.</li>
            </ul>
            <p className="text-grey">
              Furthermore, OUR BROKING might also process your data to keep you
              updated about services or promotions that might be of interest or
              align better with your preferences. The type of information we might
              collect encompasses your name, email or postal address, date of
              birth, financial specifics, PAN number, or any other details
              mandated by laws or deemed essential by our organization.
            </p>
            <p className="text-grey">
              Your data can be stored both electronically and in print. We
              prioritize the safety of your personal details, taking stringent
              measures against unauthorized access, alterations, loss, or misuse.
              Access to such data within our organization is on a strict
              "need-to-know" basis, ensuring confidentiality.
            </p>
            <p className="text-grey">
              There might be instances where we need to share your information
              with specific organizations to manage, renew, or promote the product
              or service you've opted for or to comply with legal or regulatory
              directives. Such scenarios could encompass reporting to regulatory
              bodies for health and safety, preventing illicit activities, or
              safeguarding public revenues.
            </p>
            <p className="text-grey">
              Assuring you, your personal data will never be commercialized or
              leased out. We've also fortified our online platforms to safeguard
              the data you share. This includes the deployment of firewalls, data
              encryption, and other access controls like passwords and user
              identifiers.
            </p>
            <p className="text-grey">
              If you wish to discuss any concerns related to our privacy measures
              or need clarifications about our Privacy Policy, you can:
            </p>
            <ul>
              <li>Reach out directly via our Customer Care</li>
            </ul>
          </div>
        </section>
      </main>
    </main>
    {/* scripts */}
    {/* Page specific scripts */}
    <noscript>
      &lt;p&gt; &lt;img src="//matomo.OUR
      BROKING.net/matomo.php?idsite=3&amp;amp;rec=1" style="border: 0" alt=""
      /&gt;&lt;/p &gt;
    </noscript>
  </section>
  );
}

export default Privacy_policy;