import React from 'react'
import { Link } from 'react-router-dom'
function Fund_transfer() {
  return (
    <>
      <main className="content parent-content">
        <main className="content">
          <section className="fund-sections funds-header container border-bottom text-center">
            <div style={{ marginTop: "8.7%" }} />
            <h1>Fund transfer</h1>
            <h5 className="text-grey">
              Transfer funds only from the bank account registered with OurBroking
            </h5>
          </section>
          <section className="fund-sections">
            <div className="mini-container tinytabs" id="funds_tabs">
              {/* <nav className="tabs">
            <a
              className="tab tab-deposit_funds sel"
              href="#tab-deposit_funds"
              data-id="deposit_funds"
            >
              Deposit funds
            </a>
            <a
              className="tab tab-withdraw_funds"
              href="#tab-withdraw_funds"
              data-id="withdraw_funds"
            >
              Withdraw funds
            </a>
          </nav> */}
              <div
                className="section"
                id="deposit_funds"
                style={{ display: "block" }}
              >
                <h3 className="title" style={{ display: "none" }}>
                  Deposit funds
                </h3>
                {/* <p>
              SEBI regulations mandate clients to keep their equity and
              commodity account balances separate — so OurBroking has two different
              bank accounts. There are four ways to transfer funds to the
              OurBroking account.
            </p> */}
                {/* <h4>
              UPI
              <img className="upi-logo" src="/static/images/upi-logo.png" />
            </h4>
            <p>
              Transfer funds instantly from the registered bank account using
              UPI for free. All UPI transfers must be initiated from Kite.
              OurBroking does not accept digital wallet transfers or transfers
              directly from UPI apps.
            </p> */}
                {/* <p>
              See{" "}
              <a
                target="_blank"
                href="https://support.OurBroking.com/category/funds/adding-funds/how-to-add-funds/articles/add-money-using-upi-to-my-trading-account"
              >
                How to add money to my account using UPI?
              </a>{" "}
              to know more.
            </p> */}
                {/* <h4>Payment gateway</h4> */}
                {/* <p>
              Funds can be transferred instantly through net banking. ₹9 + 18%
              GST will be charged for such transfers.
            </p> */}
                  Investors are requested to note that OUR BROKING SERVICES LLP is permitted to
receive/pay money from/to investor through designated bank accounts only named as client
bank accounts. OUR BROKING SERVICES LLP is also required to disclose these client
bank accounts to Stock Exchange. Hence, you are requested to use following client bank
accounts only for the purpose of dealings in your trading account with us. The details of these
client bank accounts are also displayed by Stock Exchanges on their website under “Know/
Locate your Stock Broker”. 
                <h4>
                  <img className="upi-logo" src="img/upi-logo.png" alt='UPI' />
                </h4>
                <p>Transfer funds instantly from the registered bank account using UPI for free. UPI transfer has a limit of ₹2,00,000 per day. Banks may have further restrictions on the amount per transaction which can be checked with respective banks.</p>
                <h4>IMPS/NEFT/RTGS transfer</h4>
                <div className="table-container">
                  <table>
                    <thead>
                      <tr>
                        <th />
                        <th>NSE/BSE (Equity, F&amp;O, Currency)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Bank Name</td>
                        <td>HDFC BANK LTD</td>
                      </tr>
                      <tr>
                        <td>Account Title</td>
                        <td>OUR BROKING SERVICES LLP USCNB AC</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Account Number Prefix</strong>
                        </td>
                        <td>
                          <strong>OBS234</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Alternate Account Number</strong>
                        </td>
                        <td>
                          <strong>57500001153641</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Account type</td>
                        <td>Current account</td>
                      </tr>
                      <tr>
                        <td>Bank Branch</td>
                        <td>FEROZE GANDHI MARKET LUDHIANA - LUDHIANA , PUNJAB</td>
                      </tr>
                      <tr>
                        <td>IFSC Code</td>
                        <td>HDFC0000634</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p className="text-12"></p>
                <ul className="list-items">
                  <li>
                  Unlike traditional bank account numbers, Our Broking's bank account numbers include alphabets. If your bank does not accept accounts with alphanumeric characters, you can use "alternate account number".
Avoid transferring funds to bank accounts that ending in *********3641, as these transactions must be manually transferred to the client's trading account. In case of any concerns send a soft copy of the bank statement, which shows a debit towards the "alternate account number".
                  </li>
                  <li>
                    Kindly ensure that correct OurBroking details are entered before
                    initiating the fund transfer.
                  </li>
                  <li>
                    OurBroking does not accept any deposits via cash or Demand
                    Draft(DD).
                  </li>
                </ul>
                <p />
                <hr />
                <p className="instant-text">
                  The time taken for the funds to be updated depends on the mode of
                  fund transfer.
                </p>
                <br />
                <div className="table-container">
                  <table>
                    <thead>
                      <tr>
                        <th>Method</th>
                        <th>Time taken</th>
                        <th>Charges</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>UPI</td>
                        <td>Instant</td>
                        <td>Free</td>
                      </tr>
                      <tr>
                        <td>NEFT/RTGS</td>
                        <td>Within 2 hours</td>
                        <td>Free at OurBroking, bank charges may apply</td>
                      </tr>
                      <tr>
                        <td>IMPS</td>
                        <td>Within 10 minutes</td>
                        <td>Free at OurBroking, bank charges may apply</td>
                      </tr>
                      <tr>
                        <td>Cheque</td>
                        <td>3 to 5 working days</td>
                        <td>Free</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
                <p>
                  If the funds don't show up in the OurBroking account within the time
                  described above, it may be because the funds were transferred from
                  an unregistered bank account or that OurBroking hasn't received the
                  bank account details from the remitting bank.
                </p>
                <ul className="list-items">
                  <li className="text-small fund-footer-notes">
                    The method of transferring funds differs between NRI and
                    individual resident accounts
                    .
                  </li>
                  <li className="text-small fund-footer-notes">
                    If an email or call is received from someone claiming to be from
                    OurBroking asking for fund transfers to an account number that is
                    not listed above, please <Link to="/Contact">contact us</Link> immediately.
                  </li>
                  <li className="text-small fund-footer-notes">
                    Never provide sensitive account information like Support Code,
                    password, client ID or personal details in response to an email.
                    If such information is provided, report it to OurBroking
                    immediately. If any spoofed (duplicate or unofficial) OurBroking
                    website is noticed, please inform OurBroking by <Link to="/Contact">raising a ticket</Link>.
                  </li>
                </ul>
              </div>
              <div
                className="section"
                id="withdraw_funds"
                style={{ display: "none" }}
              >
                <h3 className="title" style={{ display: "none" }}>
                  Withdraw funds
                </h3>
                <ul className="list-items">
                  <li>
                    Fund withdrawal requests can be placed from{" "}
                    <a href="https://console.OurBroking.com/funds/overview/">
                      Console
                    </a>{" "}
                    or <a href="https://kite.OurBroking.com/funds">Kite</a>.
                  </li>
                  <li>Funds transferred cannot be withdrawn on the same day.</li>
                  <li>
                    Fund withdrawal requests are processed at cut-off times shown in
                    the table below. If the request is placed before the cut-off
                    time, the funds are typically credited to the bank account
                    within 24 hours from the cut-off time. If the request is placed
                    after the cut-off time, it takes an additional working day (up
                    to 48 hours in total) for the funds to be credited to the bank
                    account.
                  </li>
                </ul>
                <br />
                <div className="table-container">
                  <table>
                    <thead>
                      <tr>
                        <th>Withdrawal day</th>
                        <th>Cut-off time for equity, FO, currency</th>
                        <th>Cut-off time for commodity</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Weekday</td>
                        <td>10:00 PM</td>
                        <td>8:00 AM</td>
                      </tr>
                      <tr>
                        <td>Saturday</td>
                        <td>4:30 PM</td>
                        <td>4:00 PM</td>
                      </tr>
                      <tr>
                        <td>Sunday or public holiday</td>
                        <td>Withdrawal is processed on the next working day.</td>
                        <td>Withdrawal is processed on the next working day.</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
                <p>
                  If the funds are still not received, please{" "}
                  <a
                    target="_blank"
                    href="https://support.OurBroking.com/contact-us?article=i-placed-a-withdrawal-request-24-hours-ago-why-haven-t-i-received-my-money&category=withdrawal-timeline"
                  >
                    create a ticket
                  </a>
                  .
                </p>
              </div>
            </div>
          </section>
        </main>
      </main>
    </>


  )
}

export default Fund_transfer