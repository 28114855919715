import React from 'react'
function Invest() {
  return (<>
    <section id="home" style={{ padding: 0 }}>
      <div style={{ marginTop: "8.7%" }} />
      <div className="container mt-3">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li
            className="nav-item"
            data-toggle="tooltip"
            title="Stock Brokers"
            data-placement="top"
            role="presentation"
          >
            <a
              className="nav-link active"
              id="stockbrokers-tab"
              data-bs-toggle="tab"
              href="#stockbrokers-tab-pane"
              type="button"
              role="tab"
              aria-controls="stockbrokers-tab-pane"
              aria-selected="true"
            >
              <h4>Stock Brokers</h4>
            </a>
          </li>
          <li
            className="nav-item"
            data-toggle="tooltip"
            title="Depository Participants"
            data-placement="top"
            role="presentation"
          >
            <a
              className="nav-link "
              id="dp-tab"
              data-bs-toggle="tab"
              href="#dp-tab-pane"
              type="button"
              role="tab"
              aria-controls="dp-tab-pane"
              aria-selected="false"
            >
              <h4>Depository Participants</h4>
            </a>
          </li>
          <li
            className="nav-item"
            data-toggle="tooltip"
            title="Invester Disclaimer"
            data-placement="top"
            role="presentation"
          >
            <a
              className="nav-link "
              id="others-tab"
              data-bs-toggle="tab"
              href="#others"
              type="button"
              role="tab"
              aria-controls="others-tab-pane"
              aria-selected="false"
            >
              <h4>Investor Disclaimer</h4>
            </a>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="stockbrokers-tab-pane"
            role="tabpanel"
            aria-labelledby="stockbrokers-tab"
            tabIndex={0}
          >
            <div className="d-flex justify-content-center mb-3">
              <div>
                <div className="row mb-3 text-black">
                  <div className="col-md-6 col-sm-6 my-2">
                    <div className="card h-100">
                      <div className="card-body" role="group">
                        <p className="text-align-center" style={{ fontSize: 18 }}>
                          <strong>Vision</strong>
                        </p>
                        <ul>
                          <li>
                            <p style={{ fontSize: 15 }}>
                              To provide a safe, equitable, transparent and
                              trusted platform for investors to participate across
                              asset classes with highest standards of integrity
                              for investors.
                            </p>
                          </li>
                          <ul></ul>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 my-2">
                    <div className="card h-100">
                      <div className="card-body" role="group">
                        <p className="text-align-center" style={{ fontSize: 18 }}>
                          <strong>Mission</strong>
                        </p>
                        <ul style={{ fontSize: 15 }}>
                          <li>
                            To provide high quality and dependable service through
                            innovation, capacity enhancement and use of
                            technology.
                          </li>
                          <li>
                            {" "}
                            To establish and maintain a relationship of trust and
                            ethics with the investors.
                          </li>
                          <li>
                            To observe highest standard of compliances and
                            transparency.
                          </li>
                          <li>
                            To always keep ‘protection of investors’ interest’ as
                            goal while providing service.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <div
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    Services provided to Investors
                  </div>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                  style={{}}
                >
                  <div className="accordion-body" style={{ padding: 20 }}>
                    <ol>
                      <li>Execution of trades on behalf of investors.</li>
                      <li>Issuance of Contract Notes.</li>
                      <li>
                        Issuance of intimations regarding margin due payments.
                      </li>
                      <li>
                        Facilitate execution of early pay-in obligation
                        instructions.
                      </li>
                      <li>Settlement of client’s funds.</li>
                      <li>
                        Intimation of securities held in Client Unpaid Securities
                        Account (CUSA) Account. • Issuance of retention statement
                        of funds.
                      </li>
                      <li>
                        Risk management systems to mitigate operational and market
                        risk. • Facilitate client profile changes in the system as
                        instructed by the client. • Information sharing with the
                        client w.r.t. exchange circulars.
                      </li>
                      <li>Redressal of Investor’s grievances.</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <div
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Grievance Redressal Mechanism
                  </div>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                  style={{}}
                >
                  <div className="accordion-body" style={{ padding: 20 }}>
                    <ol>
                      <li>
                        Mode of filing the complaints
                        <p>
                          Complaints can be lodged on the Exchange in the
                          following ways:
                        </p>
                        <ol type="a">
                          <li>
                            Electronic mode
                            <ol type="I">
                              <li>
                                Through{" "}
                                <a
                                  style={{ color: "rgb(58, 58, 187)" }}
                                  href="https://www.scores.gov.in/scores/Welcome.html"
                                >
                                  https://www.scores.gov.in/scores/Welcome.html
                                </a>
                              </li>
                              <li>
                                Through Respective Exchange’s web portal dedicated
                                for the filing of compliant{" "}
                                <a
                                  style={{ color: "rgb(58, 58, 187)" }}
                                  href="https://www.nseindia.com/invest/download-complaint-form-for-offline-registration"
                                >
                                  Click here to visit portal
                                </a>
                              </li>
                            </ol>
                          </li>
                          <li>
                            Offline Mode - Physical{" "}
                            <a
                              style={{ color: "rgb(58, 58, 187)" }}
                              href="https://www.scores.gov.in/scores/Welcome.html"
                            >
                              Click here to download form
                            </a>
                          </li>
                        </ol>
                      </li>
                      <li>
                        {" "}
                        Documents required for complaint resolution and
                        Multi-level dispute resolution mechanism available at the
                        Exchange.{" "}
                        <p>
                          <a
                            style={{ color: "rgb(58, 58, 187)" }}
                            href="https://static.nseindia.com//s3fs-public/inline-files/Mechanism_and_Documents.pdf"
                          >
                            Mechanism and Documents (.pdf)
                          </a>
                        </p>
                      </li>
                      <img src="img/stock-brokers.png" alt="" />
                      <li>
                        Timelines for Complaint Resolution Process at Exchanges
                      </li>
                    </ol>
                    <table className="table table-bordered border-primary">
                      <tbody>
                        <tr className="head text-white">
                          <td style={{ fontSize: 16 }} scope="col">
                            <strong>S.No</strong>
                          </td>
                          <td style={{ fontSize: 16 }} scope="col">
                            <strong>Type of Activity</strong>
                          </td>
                          <td style={{ fontSize: 16 }} scope="col">
                            <strong>Timelines for Activity</strong>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">1</th>
                          <td>Receipt of Complaint</td>
                          <td>Day of complaint (C Day).</td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>
                            Additional information sought from the investor, if
                            any, and provisionally forwarded to stock broker.
                          </td>
                          <td>C + 7 Working days.</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>Amicable Resolution.</td>
                          <td>C+8 Working Days i.e. T day.</td>
                        </tr>
                        <tr>
                          <th scope="row">4</th>
                          <td>
                            Registration of the complaint and forwarding to the
                            stock broker.
                          </td>
                          <td>T+15 Working Days.</td>
                        </tr>
                        <tr>
                          <th scope="row">5</th>
                          <td>
                            Refer to Grievance Redressal Committee (GRC), in case
                            of no amicable resolution.
                          </td>
                          <td>T+16 Working Days.</td>
                        </tr>
                        <tr>
                          <th scope="row">6</th>
                          <td>Complete resolution process post GRC.</td>
                          <td>T + 30 Working Days.</td>
                        </tr>
                        <tr>
                          <th scope="row">7</th>
                          <td>
                            In case where the GRC Member requires additional
                            information, GRC order shall be completed within.
                          </td>
                          <td>T + 45 Working Days.</td>
                        </tr>
                        <tr>
                          <th scope="row">8</th>
                          <td>Implementation of GRC Order.</td>
                          <td>
                            On receipt of GRC Order, if the order is in favour of
                            the investor, debit the funds of the stock broker.
                            Order for debit is issued immediately or as per the
                            directions given in GRC order.
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">9</th>
                          <td>
                            In case the stock broker is aggrieved by the GRC
                            order, will provide intention to avail arbitration
                          </td>
                          <td>Within 7 days from receipt of order</td>
                        </tr>
                        <tr>
                          <th scope="row">10</th>
                          <td>
                            If intention from stock broker is received and the GRC
                            order amount is upto Rs.20 lakhs
                          </td>
                          <td>
                            Investor is eligible for interim relief from Investor
                            Protection Fund (IPF).The interim relief will be 50%
                            of the GRC order amount or Rs.2 lakhs whichever is
                            less. The same shall be provided after obtaining an
                            Undertaking from the investor.
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">11</th>
                          <td>Stock Broker shall file for arbitration</td>
                          <td>
                            Within 6 months from the date of GRC recommendation
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">12</th>
                          <td>
                            In case the stock broker does not file for arbitration
                            within 6 months
                          </td>
                          <td>
                            The GRC order amount shall be released to the investor
                            after adjusting the amount released as interim relief,
                            if any.
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">13</th>
                          <td>In case of receipt of arbitration intention</td>
                          <td>
                            Obtain an undertaking from the client and pay 50% of
                            the award or 2 lakhs whichever is less to the client
                            from the IPFT, in case GRC order is upto Rs. 20 lacs
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">14</th>
                          <td>
                            Arbitration not filed within 3 months from the GRC
                            Order
                          </td>
                          <td>
                            Obtain Undertaking from the client and Release 50%
                            amount to the client. Replenish 50% amount to the IPF
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">15</th>
                          <td> Completion of arbitration proceedings</td>
                          <td>
                            Within 4 months from the date of the final appointment
                            of arbitrator
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">16</th>
                          <td>Completion of appellate proceedings</td>
                          <td>
                            Within 3 months from the date of the final appointment
                            of arbitrator
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" />
                          <td style={{ color: "red" }}>Against Companies</td>
                          <td>(Not Applicable for Commodities Markets)</td>
                        </tr>
                        <tr>
                          <th scope="row">1</th>
                          <td>
                            Complaint received in SCORES by the listed company
                          </td>
                          <td>T day</td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>Response to be obtained from Listed Company </td>
                          <td>T day + 30</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>
                            If no response received, alert to Listed company in
                            the form of reminder for Non - redressal of complaint{" "}
                          </td>
                          <td>T day + 31</td>
                        </tr>
                        <tr>
                          <th scope="row">4</th>
                          <td>Response to be obtained from Listed Company </td>
                          <td>T day + 60</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <div
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Various activities of Stock Brokers with timelines
                  </div>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                  style={{}}
                >
                  <div className="accordion-body" style={{ padding: 20 }}>
                    <table className="table table-bordered border-primary">
                      <tbody>
                        <tr className="head text-white">
                          <td>
                            <p style={{ fontSize: 16 }}>
                              <strong>S.No.</strong>
                            </p>
                          </td>
                          <td>
                            <p style={{ fontSize: 16 }}>
                              <strong>Activities</strong>
                            </p>
                          </td>
                          <td>
                            <p style={{ fontSize: 16 }}>
                              <strong>Expected Timelines</strong>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>1.</p>
                          </td>
                          <td>
                            <p>KYC entered into KRA System and CKYCR</p>
                          </td>
                          <td>
                            <p>10 days of account opening</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>2.</p>
                          </td>
                          <td>
                            <p>Client Onboarding</p>
                          </td>
                          <td>
                            <p>Immediate, but not later than one week</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>3.</p>
                          </td>
                          <td>
                            <p>Order execution</p>
                          </td>
                          <td>
                            <p>
                              Immediate on receipt of order, but not later than
                              the same day
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>4.</p>
                          </td>
                          <td>
                            <p>Allocation of Unique Client Code</p>
                          </td>
                          <td>
                            <p>Before trading</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>5.</p>
                          </td>
                          <td>
                            <p>
                              Copy of duly completed Client Registration Documents
                              to clients
                            </p>
                          </td>
                          <td>
                            <p>
                              7 days from the date of upload of Unique Client Code
                              to the Exchange by the trading member
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>6.</p>
                          </td>
                          <td>
                            <p>Issuance of contract notes</p>
                          </td>
                          <td>
                            <p>24 hours of execution of trades</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>7.</p>
                          </td>
                          <td>
                            <p>Collection of upfront margin from client</p>
                          </td>
                          <td>
                            <p>Before initiation of trade</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>8.</p>
                          </td>
                          <td>
                            <p>
                              Issuance of intimations regarding other margin due
                              payments
                            </p>
                          </td>
                          <td>
                            <p>At the end of the T day</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>9.</p>
                          </td>
                          <td>
                            <p>Settlement of client funds</p>
                          </td>
                          <td>
                            <p>
                              30 days / 90 days for running account settlement
                              (RAS) as per the preference of client.
                            </p>
                            <p>
                              If consent not given for RAS – within 24 hours of
                              pay-out
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>10.</p>
                          </td>
                          <td>
                            <p>
                              ‘Statement of Accounts’ for Funds, Securities and
                              Commodities
                            </p>
                          </td>
                          <td>
                            <p>
                              Weekly basis (Within four trading days of following
                              week)
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>11.</p>
                          </td>
                          <td>
                            <p>
                              11. Issuance of retention statement of
                              funds/commodities
                            </p>
                          </td>
                          <td>
                            <p>5 days from the date of settlement</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>12.</p>
                          </td>
                          <td>
                            <p>Issuance of Annual Global</p>
                            <p>Statement</p>
                          </td>
                          <td>
                            <p>30 days from the end of the financial year</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>13.</p>
                          </td>
                          <td>
                            <p>Investor grievances redressal</p>
                          </td>
                          <td>
                            <p>30 days from the receipt of the complaint</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                  <div
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Rights of Investors
                  </div>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                  style={{}}
                >
                  <div className="accordion-body" style={{ padding: 20 }}>
                    <ol>
                      <li>
                        Ask for and receive information from a firm about the work
                        history and background of the person handling your
                        account, as well as information about the firm itself.
                      </li>
                      <li>
                        Receive complete information about the risks, obligations,
                        and costs of any investment before investing.
                      </li>
                      <li>
                        Receive recommendations consistent with your financial
                        needs and investment objectives.
                      </li>
                      <li>
                        Receive a copy of all completed account forms and
                        agreements.
                      </li>
                      <li>
                        Receive account statements that are accurate and
                        understandable.
                      </li>
                      <li>
                        Understand the terms and conditions of transactions you
                        undertake.
                      </li>
                      <li>
                        Access your funds in a timely manner and receive
                        information about any restrictions or limitations on
                        access.
                      </li>
                      <li>
                        Receive complete information about maintenance or service
                        charges, transaction or redemption fees, and penalties.
                      </li>
                      <li>
                        Discuss your grievances with compliance officer of the
                        firm and receive prompt attention to and fair
                        consideration of your concerns.
                      </li>
                    </ol>
                    <p>
                      <a
                        href="https://static.nseindia.com//s3fs-public/inline-files/Investor_Rights.pdf"
                        style={{ color: "rgb(58, 58, 187)" }}
                      >
                        Rights of Investors (.pdf)
                      </a>
                    </p>
                    <p>
                      <a
                        href="https://static.nseindia.com//s3fs-public/inline-files/Investor_Obligations.pdf"
                        style={{ color: "rgb(58, 58, 187)" }}
                      >
                        Obligations of Investors (.pdf)
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFive">
                  <div
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Guidance pertaining to special circumstances related to market
                    activities: Default of Brokers
                  </div>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                  style={{}}
                >
                  <div className="accordion-body" style={{ padding: 20 }}>
                    <ol>
                      <li>
                        When a Broker defaults, the Exchange carries out the
                        following steps for benefit of investor:
                        <ol type="a">
                          <li>
                            Dissemination on Exchange website informing with
                            regards to the default of the Broker
                          </li>
                          <li>
                            Issue of Public Notice informing declaration of
                            default by a Broker and inviting claims within
                            specified period
                          </li>
                          <li>
                            Intimation to clients of defaulter stock brokers via
                            Emails and SMS for facilitating lodging of claims
                            within specified period
                          </li>
                        </ol>
                      </li>
                      <li>
                        Following information made available on Exchange Website
                        for information of Investors
                        <ol type="a">
                          <li>
                            Norms for eligibility of claims for compensation from
                            IPF.
                          </li>
                          <li>
                            FAQ on processing of investors’ claims against
                            Defaulter Broker
                          </li>
                          <li>Form for lodging claim against defaulter Broker</li>
                          <li>
                            Standard Operating Procedure (SOP) for handling of
                            Claims of Investors in the Cases of Default by Brokers
                          </li>
                          <li>
                            Provision to check online status of claims on Exchange
                            Website
                          </li>
                        </ol>
                      </li>
                      <li>
                        Standard Operating Procedure (SOP) for Handling of Claims
                        of Investors in the Cases of Default by Stock Brokers
                        <table className="table table-bordered border-primary">
                          <tbody>
                            <tr className="head text-white">
                              <td style={{ fontSize: 16 }} scope="col">
                                <strong>S.No</strong>
                              </td>
                              <td style={{ fontSize: 16 }} scope="col">
                                <strong>Action</strong>
                              </td>
                              <td style={{ fontSize: 16 }} scope="col">
                                <strong>Timeline</strong>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">1</th>
                              <td>Disablement of the Stock Broker</td>
                              <td>T day </td>
                            </tr>
                            <tr>
                              <th scope="row">2</th>
                              <td>
                                Pre-filled forms to be sent to clients providing
                                information regarding balances with the stock
                                broker{" "}
                              </td>
                              <td>T+30 days</td>
                            </tr>
                            <tr>
                              <th scope="row">3</th>
                              <td>
                                Claim lodgement Clients to fill the claim form and
                                provide the supporting documents
                              </td>
                              <td>
                                Within 30 days of receipt of pre-filled form
                                However, client can lodge claims till the end of 3
                                years from the date of the public notice provided
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">4</th>
                              <td>
                                Processing of claims and auditing of claims{" "}
                              </td>
                              <td>
                                Within 60 days of receipt of the claim form from
                                the clients.
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">5</th>
                              <td>Declaration of stock broker as defaulter </td>
                              <td>
                                Within 90 days from date of disablement (on
                                account of triggering of SOP) (T+90 days)
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">6</th>
                              <td>
                                Public Notice regarding declaration of defaulter{" "}
                              </td>
                              <td>
                                Within 3 working days from the date of declaration
                                of defaulter
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">7</th>
                              <td>
                                Approval of the claim (by IPF Trust on the basis
                                of recommendation of MCSGFC and Intimation to the
                                clients regarding admissibility of the claim and
                                disbursal of the eligible amount
                              </td>
                              <td>Within 15 days of declaration of default</td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingSix">
                  <div
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    DO's and DON'Ts
                  </div>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionExample"
                  style={{}}
                >
                  <div className="accordion-body" style={{ padding: 20 }}>
                    <table className="unstriped table table-bordered border-primary">
                      <tbody>
                        <tr className="head text-white">
                          <td>
                            <p>DOs</p>
                          </td>
                          <td>
                            <p>DON’Ts</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <ol>
                              <li>
                                Read all documents and conditions being agreed
                                before signing the account opening form.
                              </li>
                              <li>
                                Receive a copy of KYC, copy of account opening
                                documents and Unique Client Code.
                              </li>
                              <li>
                                Read the product / operational framework /
                                timelines related to various Trading and Clearing
                                &amp; Settlement processes.
                              </li>
                              <li>
                                Receive all information about brokerage, fees and
                                other charges levied.
                              </li>
                              <li>
                                Register your mobile number and email ID in your
                                trading, demat and bank accounts to get regular
                                alerts on your transactions.
                              </li>
                              <li>
                                If executed, receive a copy of Power of Attorney.
                                However, Power of Attorney is not a mandatory
                                requirement as per SEBI / Stock Exchanges. Before
                                granting Power of Attorney, carefully examine the
                                scope and implications of powers being granted.
                              </li>
                              <li>
                                Receive contract notes for trades executed,
                                showing transaction price, brokerage, GST and STT
                                etc. as applicable, separately, within 24 hours of
                                execution of trades.
                              </li>
                              <li>
                                Receive funds and securities / commodities on time
                                within 24 hours from pay-out.
                              </li>
                              <li>
                                Verify details of trades, contract notes and
                                statement of account and approach relevant
                                authority for any discrepancies. Verify trade
                                details on the Exchange websites from the trade
                                verification facility provided by the Exchanges.
                              </li>
                              <li>
                                Receive statement of accounts periodically. If
                                opted for running account settlement, account has
                                to be settled by the stock broker as per the
                                option given by the client (30 or 90 days).
                              </li>
                              <li>
                                In case of any grievances, approach stock broker
                                or Stock Exchange or SEBI for getting the same
                                resolved within prescribed timelines.
                              </li>
                            </ol>
                          </td>
                          <td>
                            <ol>
                              <li>Do not deal with unregistered stock broker.</li>
                              <li>
                                Do not forget to strike off blanks in your account
                                opening and KYC.
                              </li>
                              <li>
                                Do not submit an incomplete account opening and
                                KYC form.
                              </li>
                              <li>
                                Do not forget to inform any change in information
                                linked to trading account and obtain confirmation
                                of updation in the system.
                              </li>
                              <li>
                                Do not transfer funds, for the purposes of trading
                                to anyone other than a stock broker. No payment
                                should be made in name of employee of stock
                                broker.
                              </li>
                              <li>
                                Do not ignore any emails / SMSs received with
                                regards to trades done, from the Stock Exchange
                                and raise a concern, if discrepancy is observed.
                              </li>
                              <li>
                                Do not opt for digital contracts, if not familiar
                                with computers.
                              </li>
                              <li>Do not share trading password.</li>
                              <li>
                                Do not fall prey to fixed / guaranteed returns
                                schemes. Do not fall prey to fraudsters sending
                                emails and SMSs luring to trade in stocks /
                                securities promising huge profits.
                              </li>
                              <li>
                                Do not follow herd mentality for investments. Seek
                                expert and professional advice for your
                                investments.
                              </li>
                            </ol>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="dp-tab-pane"
            role="tabpanel"
            aria-labelledby="dp-tab"
            tabIndex={0}
          >
            <div className="d-flex justify-content-center mb-3">
              <div>
                <div className="row mb-3 text-black">
                  <div className="col-md-6 col-sm-6 my-2">
                    <div className="card h-100">
                      <div className="card-body" role="group">
                        <p className="text-align-center" style={{ fontSize: 18 }}>
                          <strong>Vision</strong>
                        </p>
                        <ul>
                          <li>
                            <p style={{ fontSize: 15 }}>
                              Towards making Indian Securities Market -
                              Transparent, Efficient, &amp; Investor friendly by
                              providing safe, reliable, transparent and trusted
                              record keeping platform for investors to hold and
                              transfer securities in dematerialized form.
                            </p>
                          </li>
                          <ul></ul>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 my-2">
                    <div className="card h-100">
                      <div className="card-body" role="group">
                        <p className="text-align-center" style={{ fontSize: 18 }}>
                          <strong>Mission</strong>
                        </p>
                        <ul style={{ fontSize: 15 }}>
                          <li>
                            To hold securities of investors in dematerialised form
                            and facilitate its transfer, while ensuring
                            safekeeping of securities and protecting interest of
                            investors.
                          </li>
                          <li>
                            {" "}
                            To provide timely and accurate information to
                            investors with regard to their holding and transfer of
                            securities held by them.
                          </li>
                          <li>
                            To provide the highest standards of investor
                            education, investor awareness and timely services so
                            as to enhance Investor Protection and create awareness
                            about Investor Rights.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingone">
                  <div
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseone"
                    aria-expanded="false"
                    aria-controls="collapseone"
                  >
                    Details of business transacted by the Depository and
                    Depository Participant (DP)
                  </div>
                </h2>
                <div
                  id="collapseone"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingone"
                  data-bs-parent="#accordionExample"
                  style={{}}
                >
                  <div className="accordion-body" style={{ padding: 20 }}>
                    A Depository is an organization which holds securities of
                    investors in electronic form. Depositories provide services to
                    various market participants - Exchanges, Clearing
                    Corporations, Depository Participants (DPs), Issuers and
                    Investors in both primary as well as secondary markets. The
                    depository carries out its activities through its agents which
                    are known as Depository Participants (DP). Details available
                    on the link{" "}
                    <a
                      style={{ color: "rgb(58, 58, 187)" }}
                      href="https://www.cdslindia.com/DP/dplist.aspx"
                      target="_blank" rel="noreferrer"
                    >
                      https://www.cdslindia.com/DP/dplist.aspx
                    </a>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingtwo">
                  <div
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsetwo"
                    aria-expanded="false"
                    aria-controls="collapsetwo"
                  >
                    Description of services provided by the Depository through
                    Depository Participants (DP) to investors
                  </div>
                </h2>
                <div
                  id="collapsetwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingtwo"
                  data-bs-parent="#accordionExample"
                  style={{}}
                >
                  <div className="accordion-body" style={{ padding: 20 }}>
                    <div
                      className="table-2 table-responsive"
                      style={{ marginTop: 0 }}
                    >
                      <b>(1) Basic Services</b>
                      <br />
                      <table className="table table-bordered border-primary">
                        <tbody>
                          <tr className="head text-white">
                            <td width="20px">Sr. No.</td>
                            <td width="155px">
                              Brief about the Activity / Service{" "}
                            </td>
                            <td width="155px">
                              Expected Timelines for processing by the DP after
                              receipt of proper documents
                            </td>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td style={{ textAlign: "left" }}>
                              <span>Dematerialization of securities</span>
                            </td>
                            <td style={{ textAlign: "left" }}>
                              <span>7 days</span>
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td style={{ textAlign: "left" }}>
                              <span>Rematerialization of securities</span>
                            </td>
                            <td style={{ textAlign: "left" }}>
                              <span>7 days</span>
                            </td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td style={{ textAlign: "left" }}>
                              <span>
                                Mutual Fund Conversion / Destatementization
                              </span>
                            </td>
                            <td style={{ textAlign: "left" }}>
                              <span>5 days</span>
                            </td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td style={{ textAlign: "left" }}>
                              <span>
                                Re-conversion / Restatementisation of Mutual fund
                                units
                              </span>
                            </td>
                            <td style={{ textAlign: "left" }}>
                              <span>7 days</span>
                            </td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td style={{ textAlign: "left" }}>
                              <span>Transmission of securities</span>
                            </td>
                            <td style={{ textAlign: "left" }}>
                              <span>7 days</span>
                            </td>
                          </tr>
                          <tr>
                            <td>6</td>
                            <td style={{ textAlign: "left" }}>
                              <span>Registering pledge request</span>
                            </td>
                            <td style={{ textAlign: "left" }}>
                              <span>15 days</span>
                            </td>
                          </tr>
                          <tr>
                            <td>7</td>
                            <td style={{ textAlign: "left" }}>
                              <span>Closure of demat account</span>
                            </td>
                            <td style={{ textAlign: "left" }}>
                              <span>30 days</span>
                            </td>
                          </tr>
                          <tr>
                            <td>8</td>
                            <td style={{ textAlign: "left" }}>
                              <span>Settlement Instruction</span>
                            </td>
                            <td style={{ textAlign: "left" }}>
                              <span>
                                Depositories to accept physical DIS for pay-in of
                                securities upto 4 p.m and DIS in electronic form
                                upto 6 p.m on T+1 day
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <br />
                      <b>
                        (2) Depositories provide special services like pledge,
                        hypothecation, internet based services etc. in addition to
                        their core services and these include
                      </b>
                      <table className="table table-bordered border-primary">
                        <tbody>
                          <tr className="head text-white">
                            <td width="20px">Sr. No.</td>
                            <td width="155px">Type of Activity /Service </td>
                            <td width="155px">
                              Brief about the Activity / Service{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td style={{ textAlign: "left" }}>
                              <span>Value Added Services </span>
                            </td>
                            <td style={{ textAlign: "left" }}>
                              <span>
                                Depositories also provide value added services
                                such as
                              </span>
                              <br />
                              <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#Basic_Services_Demat_Account"
                                style={{ cursor: "pointer" }}
                                target="_blank" rel="noreferrer"
                              >
                                a. Basic Services Demat Account (BSDA){" "}
                              </a>
                              <br />
                              <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#Transposition_cum_dematerialization"
                                style={{ cursor: "pointer" }}
                                target="_blank" rel="noreferrer"
                              >
                                b. Transposition cum dematerialization{" "}
                              </a>
                              <br />
                              <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#Linkages_with_Clearing_System"
                                style={{ cursor: "pointer" }}
                                target="_blank" rel="noreferrer"
                              >
                                c. Linkages with Clearing System{" "}
                              </a>
                              <br />
                              d. Distribution of cash and non-cash corporate
                              benefits (Bonus, Rights, IPOs etc.), stock lending.
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td style={{ textAlign: "left" }}>
                              <span>Consolidated Account statement (CAS)</span>
                            </td>
                            <td style={{ textAlign: "left" }}>
                              <span>
                                CAS is issued 10 days from the end of the month
                                (if there were transactions in the previous month)
                                or half yearly(if no transactions) .
                              </span>
                              <br />
                            </td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td style={{ textAlign: "left" }}>
                              <span>
                                Digitalization of services provided by the
                                depositories
                              </span>
                            </td>
                            <td style={{ textAlign: "left" }}>
                              <span>
                                Depositories offer below technology solutions and
                                e-facilities to their demat account holders
                                through DPs:
                              </span>
                              <br />
                              <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#E-account_opening"
                                style={{ cursor: "pointer" }}
                                target="_blank" rel="noreferrer"
                              >
                                a. E-account opening{" "}
                              </a>
                              <br />
                              <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#Online_instructions_for_execution"
                                style={{ cursor: "pointer" }}
                                target="_blank" rel="noreferrer"
                              >
                                b. Online instructions for execution{" "}
                              </a>
                              <br />
                              <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#e-DIS_Demat_Gateway"
                                style={{ cursor: "pointer" }}
                                target="_blank" rel="noreferrer"
                              >
                                c. e-DIS / Demat Gateway{" "}
                              </a>
                              <br />
                              <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#e-CAS_facility"
                                style={{ cursor: "pointer" }}
                                target="_blank" rel="noreferrer"
                              >
                                d. e-CAS facility
                              </a>
                              <br />
                              <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#Miscellaneous_services"
                                style={{ cursor: "pointer" }}
                                target="_blank" rel="noreferrer"
                              >
                                e. Miscellaneous services{" "}
                              </a>
                              <br />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingthree">
                  <div
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsethree"
                    aria-expanded="false"
                    aria-controls="collapsethree"
                  >
                    Details of Grievance Redressal Mechanism
                  </div>
                </h2>
                <div
                  id="collapsethree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingthree"
                  data-bs-parent="#accordionExample"
                  style={{}}
                >
                  <div className="accordion-body" style={{ padding: 20 }}>
                    <div
                      className="table-2 table-responsive"
                      style={{ marginTop: 0 }}
                    >
                      <b>(1) The Process of investor grievance redressal</b>
                      <table className="table table-bordered border-primary">
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td style={{ textAlign: "left" }}>
                              <span>Investor Complaint/ Grievances </span>
                            </td>
                            <td style={{ textAlign: "left" }}>
                              <span>
                                Investor can lodge complaint/ grievance against
                                the Depository/DP in the following ways:
                              </span>
                              <div>
                                a. Electronic mode - <br />
                                <a
                                  href="https://www.scores.gov.in/scores/Welcome.html"
                                  target="_blank" rel="noreferrer"
                                >
                                  (i) SCORES (a web based centralized grievance
                                  redressal system of SEBI){" "}
                                </a>
                                <br />
                                <a
                                  href="https://www.cdslindia.com/Footer/grievances.aspx"
                                  target="_blank" rel="noreferrer"
                                >
                                  (ii) Respective Depository’s web portal
                                  dedicated for the filing of compliant{" "}
                                </a>
                                <br />
                                <p>
                                  (iii) Emails to designated email IDs of
                                  Depository -{" "}
                                  <a href="mailto:complaints@cdslindia.com">
                                    complaints@cdslindia.com
                                  </a>
                                </p>
                              </div>
                              <div>
                                b. Offline mode :<br />
                                <p>
                                  For tracking of your grievance, we request you
                                  to submit the same online through the portal.
                                  <br />
                                  The complaints/ grievances lodged directly with
                                  the Depository shall be resolved within 30 days
                                </p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td style={{ textAlign: "left" }}>
                              <span>
                                Investor Grievance Redressal Committee of
                                Depository
                              </span>
                            </td>
                            <td style={{ textAlign: "left" }}>
                              <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#Investor_Grievance_Redressal_Committee_of_Depository"
                                style={{ cursor: "pointer" }}
                                target="_blank" rel="noreferrer"
                              >
                                Click here
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td style={{ textAlign: "left" }}>
                              <span>Arbitration proceedings</span>
                            </td>
                            <td style={{ textAlign: "left" }}>
                              <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#Arbitration_proceedings"
                                style={{ cursor: "pointer" }}
                                target="_blank" rel="noreferrer"
                              >
                                Click here
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <br />
                      <b>
                        (2) For the Multi-level complaint resolution mechanism
                        available at the Depositories please refer to link{" "}
                        <a
                          href="https://www.cdslindia.com/downloads/Investors/Complaint%20Resolution%20process%20at%20Depositories.pdf"
                          target="_blank" rel="noreferrer"
                        >
                          Complaint Resolution process at Depositories
                        </a>
                      </b>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingfour">
                  <div
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsefour"
                    aria-expanded="false"
                    aria-controls="collapsefour"
                  >
                    Guidance pertaining to special circumstances related to market
                    activities: Termination of the Depository Participant
                  </div>
                </h2>
                <div
                  id="collapsefour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingfour"
                  data-bs-parent="#accordionExample"
                  style={{}}
                >
                  <div className="accordion-body" style={{ padding: 20 }}>
                    <div
                      className="table-2 table-responsive"
                      style={{ marginTop: 0 }}
                    >
                      <table className="table table-bordered border-primary">
                        <tbody>
                          <tr className="head text-white">
                            <td width="20px">Sr. No.</td>
                            <td width="100px">Type of special circumstances </td>
                            <td width="100px">
                              Timelines for the Activity/ Service{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td style={{ textAlign: "left" }}>
                              <li>
                                Depositories to terminate the participation in
                                case a participant no longer meets the eligibility
                                criteria and/or any other grounds as mentioned in
                                the bye laws like suspension of trading member by
                                the Stock Exchanges.
                              </li>
                              <br />
                              <li>
                                Participant surrenders the participation by its
                                own wish.
                              </li>
                            </td>
                            <td style={{ textAlign: "left" }}>
                              <span>
                                Client will have a right to transfer all its
                                securities to any other Participant of its choice
                                without any charges for the transfer within 30
                                days from the date of intimation by way of
                                letter/email.
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingfive">
                  <div
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsefive"
                    aria-expanded="false"
                    aria-controls="collapsefive"
                  >
                    Dos and Don'ts for invester
                  </div>
                </h2>
                <div
                  id="collapsefive"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingfive"
                  data-bs-parent="#accordionExample"
                  style={{}}
                >
                  <div className="accordion-body" style={{ padding: 20 }}>
                    <p style={{ textAlign: "justify" }}>
                      For Do’s and Don’ts please refer to the link{" "}
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#Dos_and_Don’ts_for_Investor"
                        style={{ cursor: "pointer" }}
                        target="_blank" rel="noreferrer"
                      >
                        Dos and Don’ts for Investor
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingsix">
                  <div
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsesix"
                    aria-expanded="false"
                    aria-controls="collapsesix"
                  >
                    Rights of invester
                  </div>
                </h2>
                <div
                  id="collapsesix"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingsix"
                  data-bs-parent="#accordionExample"
                  style={{}}
                >
                  <div className="accordion-body" style={{ padding: 20 }}>
                    <p style={{ textAlign: "justify" }}>
                      For rights please refer to the link{" "}
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#Rights_of_investors"
                        style={{ cursor: "pointer" }}
                        target="_blank" rel="noreferrer"
                      >
                        Rights of Invester
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              {/* najcskdnjlcndjoncdsj */}
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="others"
            role="tabpanel"
            aria-labelledby="others-tab"
            tabIndex={0}
          >
            <ul className="list-div" style={{ padding: 15 }}>
              <li>
                {" "}
                Rights and Obligations of Beneficial Owner and Depository
                Participant as prescribed by SEBI and Depositories <br />{" "}
                <a
                  href="/pdf/RIGHTS-AND-OBLIGATIONS-OF-BENEFICIAL-OWNER-AND-DEPOSITORY-PARTICIPANT-AS-PRESCRIBED-BY-SEBI-AND-DEPOSITORIES.pdf"
                  style={{ cursor: "pointer", color: "blue" }}
                  target="_blank" rel="noreferrer"
                >
                  Click here
                </a>
              </li>
              <li>
                {" "}
                Rights and Obligations of Stock Brokers, Sub-Brokers and Clients
                as prescribed by SEBI and Stock Exchanges <br />{" "}
                <a
                  href="https://www.sebi.gov.in/sebi_data/commondocs/ann4rights_p.pdf"
                  style={{ cursor: "pointer", color: "blue" }}
                  target="_blank" rel="noreferrer"
                >
                  Click here
                </a>
              </li>
              <li>
                {" "}
                Risk Disclosure Document For Capital Market And Derivatives
                Segments <br />{" "}
                <a
                  href="https://www.sebi.gov.in/sebi_data/commondocs/ann5risk_p.pdf"
                  style={{ cursor: "pointer", color: "blue" }}
                  target="_blank" rel="noreferrer"
                >
                  Click here
                </a>
              </li>
              <li>
                {" "}
                Guidance note - Do's and Don'ts for trading on the exchange(s) for
                investors <br />
                <a
                  href="https://www.sebi.gov.in/sebiweb/about/AboutAction.do?doQuickResoLink=yes&quickResoId=50"
                  style={{ cursor: "pointer", color: "blue" }}
                  target="_blank" rel="noreferrer"
                >
                  Click here
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <div
      className="modal fade"
      id="Basic_Services_Demat_Account"
      tabIndex={-1}
      aria-labelledby="Basic_Services_Demat_Account_(BSDA)Title"
      style={{ display: "none" }}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title"
              id="Basic_Services_Demat_Account_(BSDA)Title"
            >
              Basic Services Demat Account (BSDA)
            </h5>
            <div
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></div>
          </div>
          <div className="modal-body">
            <div className="poup-holder">
              <div className="row">
                <ul className="list-div">
                  <li>
                    The facility of BSDA with limited services for eligible
                    individuals was introduced with the objective of achieving
                    wider financial inclusion and to encourage holding of demat
                    accounts. No Annual Maintenance Charges (AMC) shall be levied,
                    if the value of securities holding is upto Rs. 50,000. For
                    value of holdings between Rs 50,001- 2,00,000, AMC not
                    exceeding Rs 100 is chargeable. In case of debt securities,
                    there are no AMC charges for holding value upto Rs 1,00,000
                    and a maximum of Rs 100 as AMC is chargeable for value of
                    holdings between Rs 1,00,001 and Rs 2,00,000.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="modal fade"
      id="Transposition_cum_dematerialization"
      tabIndex={-1}
      aria-labelledby="Transposition_cum_dematerializationTitle"
      style={{ display: "none" }}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title"
              id="Transposition_cum_dematerializationTitle"
            >
              Transposition cum dematerialization
            </h5>
            <div
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></div>
          </div>
          <div className="modal-body">
            <div className="poup-holder">
              <div className="row">
                <ul className="list-div">
                  <li>
                    In case of transposition-cum-dematerialisation, client can get
                    securities dematerialised in the same account if the names
                    appearing on the certificates match with the names in which
                    the account has been opened but are in a different order. The
                    same may be done by submitting the security certificates along
                    with the Transposition Form and Demat Request Form.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="modal fade"
      id="Linkages_with_Clearing_System"
      tabIndex={-1}
      aria-labelledby="Linkages_with_Clearing_SystemTitle"
      style={{ display: "none" }}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="Linkages_with_Clearing_SystemTitle">
              Linkages with Clearing System
            </h5>
            <div
              type="button"
              style={{ color: "aqua" }}
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></div>
          </div>
          <div className="modal-body">
            <div className="poup-holder">
              <div className="row">
                <ul className="list-div">
                  <li>
                    Linkages with Clearing System for actual delivery of
                    securities to the clearing system from the selling brokers and
                    delivery of securities from the clearing system to the buying
                    broker.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="modal fade"
      id="E-account_opening"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="E-account_openingTitle"
      style={{ display: "none" }}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="E-account_openingTitle">
              E-account opening
            </h5>
            <div
              type="button"
              style={{ color: "aqua" }}
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></div>
          </div>
          <div className="modal-body">
            <div className="poup-holder">
              <div className="row">
                <ul className="list-div">
                  <li>
                    Account opening through digital mode, popularly known as
                    “On-line Account opening”, wherein investor intending to open
                    the demat account can visit DP website, fill in the required
                    information, submit the required documents, conduct video IPV
                    and demat account gets opened without visiting DPs office.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="modal fade"
      id="Online_instructions_for_execution"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="Online_instructions_for_executionTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title"
              id="Online_instructions_for_executionTitle"
            >
              Online instructions for execution
            </h5>
            <div
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></div>
          </div>
          <div className="modal-body">
            <div className="poup-holder">
              <div className="row">
                <ul className="list-div">
                  <li>
                    Online instructions for execution internet-enabled services
                    like Speed-e (NSDL) &amp; Easiest (CDSL) empower a demat
                    account holder in managing his/her securities
                    ‘anytime-anywhere’ in an efficient and convenient manner and
                    submit instructions online without the need to use paper.
                    These facilities allows Beneficial Owner (BO) to submit
                    transfer instructions and pledge instructions including margin
                    pledge from their demat account. The instruction facilities
                    are also available on mobile applications through android,
                    windows and IOS platforms.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="modal fade"
      id="e-DIS_Demat_Gateway"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="e-DIS_Demat_GatewayTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="e-DIS_Demat_GatewayTitle">
              e-DIS / Demat Gateway
            </h5>
            <div
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></div>
          </div>
          <div className="modal-body">
            <div className="poup-holder">
              <div className="row">
                <ul className="list-div">
                  <li>
                    Investors can give instructions for transfer of securities
                    through e-DIS apart from physical DIS. Here, for on-market
                    transfer of securities, investors need to provide settlement
                    number along with the ISIN and quantity of securities being
                    authorized for transfer. Client shall be required to authorize
                    each e-DIS valid for a single settlement number / settlement
                    date, by way of OTP and PIN/password, both generated at
                    Depositories end. Necessary risk containment measures are
                    being adopted by Depositories in this regard.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="modal fade"
      id="e-CAS_facility"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="e-CAS_facilityTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="e-CAS_facilityTitle">
              e-CAS facility
            </h5>
            <div
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></div>
          </div>
          <div className="modal-body">
            <div className="poup-holder">
              <div className="row">
                <ul className="list-div">
                  <li>
                    Consolidated Account Statements are available online and could
                    also be accessed through mobile app to facilitate the
                    investors to view their holdings in demat form.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="modal fade"
      id="Miscellaneous_services"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="Miscellaneous_servicesTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="Miscellaneous_servicesTitle">
              Miscellaneous services
            </h5>
            <div
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></div>
          </div>
          <div className="modal-body">
            <div className="poup-holder">
              <div className="row">
                <ul className="list-div">
                  <li>
                    Transaction alerts through SMS, e-locker facilities, chatbots
                    for instantaneously responding to investor queries etc. have
                    also been developed.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="modal fade"
      id="Investor_Grievance_Redressal_Committee_of_Depository"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="Investor_Grievance_Redressal_Committee_of_DepositoryTitle"
      aria-modal="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title"
              id="Investor_Grievance_Redressal_Committee_of_DepositoryTitle"
            >
              Investor Grievance Redressal Committee of Depository
            </h5>
            <div
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></div>
          </div>
          <div className="modal-body">
            <div className="poup-holder">
              <div className="row">
                <ul className="list-div">
                  <span>
                    If no amicable resolution is arrived, then the Investor has
                    the option to refer the complaint/ grievance to the Grievance
                    Redressal Committee (GRC) of the Depository. Upon receipt of
                    reference, the GRC will endeavor to resolve the complaint/
                    grievance by hearing the parties, and examining the necessary
                    information and documents
                  </span>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="modal fade"
      id="Arbitration_proceedings"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="Arbitration_proceedingsTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="Arbitration_proceedingsTitle">
              Arbitration proceedings
            </h5>
            <div
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></div>
          </div>
          <div className="modal-body">
            <div className="poup-holder">
              <div className="row">
                <ul className="list-div">
                  <span>
                    The Investor may also avail the arbitration mechanism set out
                    in the Byelaws and Business Rules/Operating Instructions of
                    the Depository in relation to any grievance, or dispute
                    relating to depository services. The arbitration reference
                    shall be concluded by way of issue of an arbitral award within
                    4 months from the date of appointment of arbitrator(s).
                  </span>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="modal fade"
      id="Dos_and_Don’ts_for_Investor"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="Dos_and_Don’ts_for_InvestorTitle"
      aria-modal="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="Dos_and_Don’ts_for_InvestorTitle">
              Dos and Don’ts for Investor
            </h5>
            <div
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></div>
          </div>
          <div className="modal-body">
            <div className="poup-holder">
              <div className="row">
                <div className="table-2 table-responsive">
                  <table className="table table-bordered border-primary">
                    <tbody>
                      <tr className="head text-white">
                        <td style={{ fontSize: 16 }} scope="col">
                          <strong>S.No</strong>
                        </td>
                        <td style={{ fontSize: 16 }} scope="col">
                          <strong>Guidance</strong>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">1</th>
                        <td>
                          Always deal with a SEBI registered Depository
                          Participant for opening a demat account.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>
                          Read all the documents carefully before signing them
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>
                          Before granting Power of attorney to operate your demat
                          account to an intermediary like Stock Broker, Portfolio
                          Management Services (PMS) etc., carefully examine the
                          scope and implications of powers being granted.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">4</th>
                        <td>
                          Always make payments to registered intermediary using
                          banking channels. No payment should be made in name of
                          employee of intermediary.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">5</th>
                        <td>
                          Accept the Delivery Instruction Slip (DIS) book from
                          your DP only (pre-printed with a serial number along
                          with your Client ID) and keep it in safe custody and do
                          not sign or issue blank or partially filled DIS slips.
                          <br />
                          Always mention the details like ISIN, number of
                          securities accurately. In case of any queries, please
                          contact your DP or broker and it should be signed by all
                          demat account holders.
                          <br />
                          Strike out any blank space on the slip and Cancellations
                          or corrections on the DIS should be initialed or signed
                          by all the account holder(s).
                          <br />
                          Do not leave your instruction slip book with anyone
                          else.
                          <br />
                          Do not sign blank DIS as it is equivalent to a bearer
                          cheque.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">6</th>
                        <td>
                          Inform any change in your Personal Information (for
                          example address or Bank Account details, email ID,
                          Mobile number) linked to your demat account in the
                          prescribed format and obtain confirmation of updation in
                          system
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">7</th>
                        <td>
                          Mention your Mobile Number and email ID in account
                          opening form to receive SMS alerts and regular updates
                          directly from depository.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">8</th>
                        <td>
                          Always ensure that the mobile number and email ID linked
                          to your demat account are the same as provided at the
                          time of account opening/updation.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">9</th>
                        <td>
                          Do not share password of your online trading and demat
                          account with anyone.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">10</th>
                        <td>
                          Do not share One Time Password (OTP) received from
                          banks, brokers, etc. These are meant to be used by you
                          only.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">11</th>
                        <td>
                          Do not share login credentials of e-facilities provided
                          by the depositories such as e-DIS/demat gateway,
                          SPEED-e/easiest etc. with anyone else.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">12</th>
                        <td>
                          Demat is mandatory for any transfer of securities of
                          Listed public limited companies with few exceptions.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">13</th>
                        <td>
                          If you have any grievance in respect of your demat
                          account, please write to designated email IDs of
                          depositories or you may lodge the same with SEBI online
                          at{" "}
                          <a href="https://scores.gov.in/scores/Welcome.html">
                            https://scores.gov.in/scores/Welcome.html
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">14</th>
                        <td>
                          Keep a record of documents signed, DIS issued and
                          account statements received.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">15</th>
                        <td>
                          {" "}
                          As Investors you are required to verify the transaction
                          statement carefully for all debits and credits in your
                          account. In case of any unauthorized debit or credit,
                          inform the DP or your respective Depository.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">16</th>
                        <td>
                          Appoint a nominee to facilitate your heirs in obtaining
                          the securities in your demat account, on completion of
                          the necessary procedures.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">17</th>
                        <td>
                          Register for Depository's internet based facility or
                          download mobile app of the depository to monitor your
                          holdings.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">18</th>
                        <td>
                          Ensure that, both, your holding and transaction
                          statements are received periodically as instructed to
                          your DP. You are entitled to receive a transaction
                          statement every month if you have any transactions.{" "}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">19</th>
                        <td>
                          Do not follow herd mentality for investments. Seek
                          expert and professional advice for your investments
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">20</th>
                        <td>Beware of assured/fixed returns.</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="modal fade"
      id="Rights_of_investors"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="Rights_of_investorsTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="Arbitration_proceedingsTitle">
              Rights of investors
            </h5>
            <div
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></div>
          </div>
          <div className="modal-body">
            <div className="poup-holder">
              <div className="row">
                <ul className="list-div">
                  <li>
                    Receive a copy of KYC, copy of account opening documents.
                  </li>
                  <li>
                    No minimum balance is required to be maintained in a demat
                    account.
                  </li>
                  <li>No charges are payable for opening of demat accounts.</li>
                  <li>
                    If executed, receive a copy of Power of Attorney. However,
                    Power of Attorney is not a mandatory requirement as per SEBI /
                    Stock Exchanges. You have the right to revoke any
                    authorization given at any time.
                  </li>
                  <li>
                    You can open more than one demat account in the same name with
                    single DP/ multiple DPs.
                  </li>
                  <li>
                    Receive statement of accounts periodically. In case of any
                    discrepancies in statements, take up the same with the DP
                    immediately. If the DP does not respond, take up the matter
                    with the Depositories.
                  </li>
                  <li>
                    Pledge and /or any other interest or encumbrance can be
                    created on demat holdings.
                  </li>
                  <li>
                    Right to give standing instructions with regard to the
                    crediting of securities in demat account.
                  </li>
                  <li>
                    Investor can exercise its right to freeze/defreeze his/her
                    demat account or specific securities / specific quantity of
                    securities in the account, maintained with the DP.
                  </li>
                  <li>
                    In case of any grievances, Investor has right to approach
                    Participant or Depository or SEBI for getting the same
                    resolved within prescribed timelines.
                  </li>
                  <li>
                    Every eligible investor shareholder has a right to cast its
                    vote on various resolutions proposed by the companies for
                    which Depositories have developed an internet based ‘e-Voting’
                    platform.
                  </li>
                  <li>
                    Receive information about charges and fees. Any charges/tariff
                    agreed upon shall not increase unless a notice in writing of
                    not less than thirty days is given to the Investor.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
}

export default Invest;