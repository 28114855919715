import React, { useState } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Card, Image } from "react-bootstrap";
import Mail from "./icons/mail.png";
import Location from "./icons/location.png";
import Phone from "./icons/phone-call.png";
import { useNavigate } from "react-router-dom";

const MyForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    xnQsjsdp: "edbsn1d8d2e6bed3c705918f0969177cffca2",
    xmIwtLD:
      "edbsn961867d6876142915d1a78b75cab80301cb4934c2e66419a39e7825e8d0f07fc",
    xJdfEaS: "",
    actionType: "Q2FzZXM=",
    ReturnURL: "*",
    "First Name": "",
    "Contact Name": "",
    Email: "",
    Subject: "",
    Description: "",
    attachments: [],
  });

  const options = {
    method: "POST",
    url: "https://desk.zoho.in/support/WebToCase",
    params: formData,
  };

  const validEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validEmail(formData.Email)) {
      alert("Please enter a valid email address.");
      return;
    }
    try {
      const response = await axios.request(options);
      // console.log("Response:", response.data);

      // Check for success
      // if (response.status === 200) {
      //   navigate("/formsubmitted");
      // } else {
      //   console.error("Form submission failed:", response.data);
      // }
    } catch (error) {
      console.error("Response error:", error);
    }
    navigate("/contact");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClose = () => {
    // You can add code here to hide the form or navigate to a different route.
    navigate("/contact"); // Navigate back to the home page or any other specified route.
  };

  return (
    <div className="contact-form-container">
      <div style={{ marginTop: "8%" }} />
      <h1>Get in Touch</h1>
      <div className="contact-form-details">
        <div className="contact-details">
          <Card style={{ borderRadius: "15px", height: "100%" }} bg="warning">
            <Card.Body className="contact-details-items">
              <Card.Title>Contact Information</Card.Title>
              <Card.Subtitle className="mb-3 text-muted">
                Making your money work for you, and your dreams come true.
              </Card.Subtitle>
              <div className="detail">
                <Card.Text>
                  <img src={Phone} alt="Phone" />&nbsp; 0161-4072791
                </Card.Text>
                <Card.Text>
                  <a href="mailto:wecare@ourbroking.com">
                    <Image src={Mail} alt="Mail" />
                  </a>&nbsp; wecare@ourbroking.com
                </Card.Text>
                <Card.Text>
                  <img src={Location} alt="Location" />&nbsp; OUR BROKING SERVICES LLP - Room 561, LSE Building, Firoze Gandhi Market, Ludhiana - 141001, Punjab, India
                </Card.Text>
              </div>
            </Card.Body>
          </Card>
        </div>
        <Form className="form-container" onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label className="form-label">User Name:</Form.Label>
            <Form.Control
              type="text"
              name="Contact Name"
              value={formData["Contact Name"]}
              onChange={handleChange}
              required
              placeholder="User Name"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="form-label">First Name:</Form.Label>
            <Form.Control
              type="text"
              name="First Name"
              value={formData["First Name"]}
              onChange={handleChange}
              required
              placeholder="First Name"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="form-label">Email address:</Form.Label>
            <Form.Control
              type="email"
              name="Email"
              value={formData.Email}
              onChange={handleChange}
              required
              placeholder="Enter Email"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="form-label">Subject:</Form.Label>
            <Form.Control
              type="text"
              name="Subject"
              value={formData.Subject}
              onChange={handleChange}
              required
              placeholder="Subject"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="form-label">Description:</Form.Label>
            <Form.Control
              type="text"
              name="Description"
              value={formData.Description}
              onChange={handleChange}
              required
              placeholder="Description"
            />
          </Form.Group>

          <div className="form-button">
            <Button className="form-button" variant="warning" type="submit">
              <b>Submit</b>
            </Button>
            {/* Add the close button here */}
            <Button
              className="form-button"
              variant="secondary"
              onClick={handleClose}
              style={{ marginLeft: "10px" }}
            >
              <b>Close</b>
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default MyForm;
